import type { ClerkAPIError, EmailCodeFactor } from "@clerk/types"
import type { SubmissionResult } from "@conform-to/react"
import type { LanguageTerm } from "../lang/get-lang.server.ts"

import * as Icon from "@iyk/icons"
import * as UI from "@iyk/ui"
import * as Sentry from "@sentry/remix"
import * as React from "react"

import { useSignIn, useSignUp } from "@clerk/remix"
import { isClerkAPIResponseError } from "@clerk/remix/errors"
import { getFormProps, getInputProps, useForm } from "@conform-to/react"
import { getZodConstraint, parseWithZod } from "@conform-to/zod"
import { toSentenceCase, toTemplate } from "@iyk/string"
import { Form, Link, useRevalidator } from "@remix-run/react"
import { z } from "zod"
import {
  PRIVACY_POLICY_URL,
  SONY_TEAMS,
  TERMS_AND_SERVICES_URL,
  THE_ORCHARD_TEAMS,
} from "../../lib/constants.ts"
import { InputOTP, InputOTPGroup, InputOTPSlot } from "../../lib/ui/input-otp.tsx"
import { useLang } from "../lang/use-lang.ts"
import { AddWalletDialog } from "./add-wallet-dialog.tsx"

const Pages = {
  EMAIL_INPUT: "EMAIL_INPUT",
  VERIFY_CODE: "VERIFY_CODE",
  CHOOSE_WALLET: "CHOOSE_WALLET",
} as const
type ObjectValues<T> = T[keyof T]
type Page = ObjectValues<typeof Pages>

type LoginFormProps = {
  onLoginComplete?: () => void
  onGoBackClick?: () => void
  showChooseWalletStep?: boolean
  teamId?: number
}

export function LoginForm({
  onLoginComplete,
  onGoBackClick,
  showChooseWalletStep,
  teamId,
}: LoginFormProps) {
  const [page, setPage] = React.useState<Page>(Pages.EMAIL_INPUT)
  const [isSigningUp, setIsSigningUp] = React.useState(false)
  const [email, setEmail] = React.useState("")

  switch (page) {
    case Pages.EMAIL_INPUT:
      return (
        <EmailInputPage
          onSuccess={(email: string) => {
            setEmail(email)
            setPage(Pages.VERIFY_CODE)
          }}
          setIsSigningUp={setIsSigningUp}
          onGoBackClick={onGoBackClick}
          teamId={teamId}
        />
      )
    case Pages.VERIFY_CODE:
      return (
        <VerifyCodePage
          isSigningUp={isSigningUp}
          setPage={setPage}
          email={email}
          onLoginComplete={onLoginComplete}
          showChooseWalletStep={showChooseWalletStep}
        />
      )
    case Pages.CHOOSE_WALLET:
      return <ChooseWallet onLoginComplete={onLoginComplete} />
    default:
      return null
  }
}

const emailInputSchema = z.object({
  email: z
    .string({ required_error: "EMAIL_IS_REQUIRED" as LanguageTerm })
    .email("EMAIL_IS_INVALID" as LanguageTerm),
})

function EmailInputPage({
  onSuccess,
  setIsSigningUp,
  onGoBackClick,
  teamId,
}: {
  onSuccess: (email: string) => void
  onGoBackClick?: () => void
  setIsSigningUp: React.Dispatch<React.SetStateAction<boolean>>
  teamId?: number
}) {
  const lang = useLang()
  const { isLoaded: isSignInLoaded, signIn } = useSignIn()
  const { isLoaded: isSignUpLoaded, signUp } = useSignUp()
  const [isSubmitting, setIsSubmitting] = React.useState(false)
  const [isGoogleLoading, setIsGoogleLoading] = React.useState(false)
  const [isAppleLoading, setIsAppleLoading] = React.useState(false)
  const [form, fields] = useForm({
    constraint: getZodConstraint(emailInputSchema),
    shouldValidate: "onBlur",
    shouldRevalidate: "onInput",

    onValidate({ formData }) {
      return parseWithZod(formData, { schema: emailInputSchema })
    },

    async onSubmit(e, { formData }) {
      e.preventDefault()
      setIsSubmitting(true)

      const result = parseWithZod(formData, { schema: emailInputSchema })

      if (result.status === "success") {
        const { email } = result.value

        if (!isSignInLoaded || !isSignUpLoaded) return

        try {
          setIsSigningUp(false)

          const { supportedFirstFactors } = await signIn.create({ identifier: email })

          if (!supportedFirstFactors) return

          // Find the emailAddressId from all the available first factors for the current signIn
          const firstEmailFactor = supportedFirstFactors.find(
            (factor): factor is EmailCodeFactor => {
              return factor.strategy === "email_code"
            },
          )

          if (!firstEmailFactor) return

          await signIn.prepareFirstFactor({
            strategy: "email_code",
            emailAddressId: firstEmailFactor.emailAddressId,
          })
        } catch (error) {
          if (isClerkAPIResponseError(error)) {
            if (error.errors[0].code === "form_identifier_not_found") {
              setIsSigningUp(true)

              await signUp.create({ emailAddress: email })
              await signUp.prepareEmailAddressVerification({ strategy: "email_code" })
            }
          }
        } finally {
          setIsSubmitting(false)
        }

        onSuccess(email)
      }
    },
  })

  const handleGoogleSignIn = async () => {
    if (!isSignInLoaded) return
    setIsGoogleLoading(true)
    try {
      const redirectUrlComplete =
        window.location.origin + window.location.pathname + window.location.search
      const redirectUrl = "/sso-callback?redirect_url=" + redirectUrlComplete

      await signIn.authenticateWithRedirect({
        strategy: "oauth_google",
        redirectUrl,
        redirectUrlComplete,
      })
    } catch (err) {
      console.error("Google sign in error:", err)
      setIsGoogleLoading(false)
    }
  }

  const handleAppleSignIn = async () => {
    if (!isSignInLoaded) return
    setIsAppleLoading(true)
    try {
      const redirectUrlComplete =
        window.location.origin + window.location.pathname + window.location.search
      const redirectUrl = "/sso-callback?redirect_url=" + redirectUrlComplete

      await signIn.authenticateWithRedirect({
        strategy: "oauth_apple",
        redirectUrl,
        redirectUrlComplete,
      })
    } catch (err) {
      console.error("Apple sign in error:", err)
      setIsAppleLoading(false)
    }
  }

  return (
    <div className="flex flex-col gap-y-4">
      <UI.Title>{toSentenceCase(lang.terms.LOGIN_OR_SIGNUP)}</UI.Title>

      <div className="flex flex-col gap-2">
        <UI.Button
          type="button"
          kind="outline"
          size="md"
          disabled={isGoogleLoading}
          onClick={handleGoogleSignIn}
          className="w-full flex items-center justify-center gap-2"
        >
          {isGoogleLoading ? (
            <Icon.Loading className="size-3" />
          ) : (
            <Icon.Google className="size-4" />
          )}
          Continue with Google
        </UI.Button>

        <UI.Button
          type="button"
          kind="outline"
          size="md"
          disabled={isAppleLoading}
          onClick={handleAppleSignIn}
          className="w-full flex items-center justify-center gap-2"
        >
          {isAppleLoading ? <Icon.Loading className="size-3" /> : <Icon.Apple className="size-4" />}
          Continue with Apple
        </UI.Button>
      </div>

      <div className="relative">
        <div className="absolute inset-0 flex items-center">
          <div className="w-full border-t border-gray-6" />
        </div>
        <div className="relative flex justify-center text-sm">
          <span className="px-2 bg-background text-gray-11">or</span>
        </div>
      </div>

      <Form method="POST" {...getFormProps(form)} className="text-sm">
        <div id={form.errorId}>{form.errors}</div>
        <div className="flex flex-col gap-2">
          <input
            data-1p-ignore
            className={UI.cx(
              "flex w-stretch px-3 py-2 bshadow bshadow-gray-4 rounded-md bg-background text-base transition-colors placeholder:text-gray-11 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-gray-5 focus-visible:bshadow-gray-8 disabled:cursor-not-allowed disabled:opacity-50 autofill:!bg-transparent",
              fields.email.errors && "bshadow-tomato-11",
            )}
            placeholder={toSentenceCase(lang.terms.EMAIL)}
            {...getInputProps(fields.email, { type: "email" })}
          />
          {fields.email.errors && (
            <UI.Text size="sm" className="text-tomato-11" id={fields.email.errorId}>
              {fields.email.errors
                .map((error) => toSentenceCase(lang.terms[error as any as LanguageTerm]))
                .join(". ")}
            </UI.Text>
          )}
        </div>
        <UI.Button
          type="submit"
          kind="solid"
          size="md"
          disabled={isSubmitting}
          className="w-full mt-3"
        >
          {isSubmitting && <Icon.Loading className="size-3" />}
          {toSentenceCase(lang.terms.CONTINUE)}
        </UI.Button>
      </Form>
      <TermsAndPrivacyText langName={lang.name} teamId={teamId} />
      {onGoBackClick && <GoBackButton onClick={onGoBackClick} />}
    </div>
  )
}

function TermsAndPrivacyText({ langName, teamId }: { langName: string; teamId?: number }) {
  return getTermsAndPrivacyTextForTeam(langName, teamId)
}

function getTermsAndPrivacyTextForTeam(langName: string, teamId?: number) {
  const lang = useLang()

  if (teamId && SONY_TEAMS.includes(teamId)) {
    // Sony Terms and Privacy Text
    return (
      <UI.Text className="text-xs text-gray-10 text-balance">
        By signing up, you have read, understood, and agree to the processing of your data and use
        of cookies in accordance with the{" "}
        <Link
          className="underline whitespace-nowrap"
          to={TERMS_AND_SERVICES_URL}
          target="_blank"
          rel="noreferrer"
        >
          IYK Terms and Conditions
        </Link>
        {", "}
        <Link
          className="underline whitespace-nowrap"
          to={PRIVACY_POLICY_URL}
          target="_blank"
          rel="noreferrer"
        >
          IYK Privacy Policy
        </Link>
        {", and "}
        <Link
          className="underline whitespace-nowrap"
          to="https://www.sonymusic.co.uk/privacy"
          target="_blank"
          rel="noreferrer"
        >
          Sony Music Privacy & Cookie Policy
        </Link>
        .
      </UI.Text>
    )
  } else if (teamId && THE_ORCHARD_TEAMS.includes(teamId)) {
    // The Orchard Terms and Privacy Text
    return (
      <UI.Text className="text-xs text-gray-10 text-balance">
        By signing up, you have read, understood, and agree to the processing of your data and use
        of cookies in accordance with the{" "}
        <Link
          className="underline whitespace-nowrap"
          to={TERMS_AND_SERVICES_URL}
          target="_blank"
          rel="noreferrer"
        >
          IYK Terms and Conditions
        </Link>
        {", "}
        <Link
          className="underline whitespace-nowrap"
          to={PRIVACY_POLICY_URL}
          target="_blank"
          rel="noreferrer"
        >
          IYK Privacy Policy
        </Link>
        {", and "}
        <Link
          className="underline whitespace-nowrap"
          to="https://cdn.sonymusicfans.com/The%20Orchard%20UK/The%20Orchard%20-%20Privacy%20%26%20Cookie%20Policy%2003.06.2020%20(1).pdf"
          target="_blank"
          rel="noreferrer"
        >
          The Orchard's Privacy & Cookie Policy
        </Link>
        .
      </UI.Text>
    )
  } else {
    // IYK Default Terms and Privacy Text
    if (langName === "ko") {
      return (
        <UI.Text className="text-xs text-gray-10 text-balance">
          계속 진행하면{" "}
          <Link
            className="underline whitespace-nowrap"
            to={TERMS_AND_SERVICES_URL}
            target="_blank"
            rel="noreferrer"
          >
            {lang.terms.TERMS_OF_SERVICE}{" "}
          </Link>{" "}
          과{" "}
          <Link
            className="underline whitespace-nowrap"
            to={PRIVACY_POLICY_URL}
            target="_blank"
            rel="noreferrer"
          >
            {lang.terms.PRIVACY_POLICY}
          </Link>{" "}
          에 동의합니다.
        </UI.Text>
      )
    }

    return (
      <UI.Text className="text-xs text-gray-10 text-balance">
        {toSentenceCase(lang.terms.BY_CONTINUING_YOU_AGREE_TO_THE)}{" "}
        <Link
          className="underline whitespace-nowrap"
          to={TERMS_AND_SERVICES_URL}
          target="_blank"
          rel="noreferrer"
        >
          {lang.terms.TERMS_OF_SERVICE}
        </Link>{" "}
        {lang.terms.AND}{" "}
        <Link
          className="underline whitespace-nowrap"
          to={PRIVACY_POLICY_URL}
          target="_blank"
          rel="noreferrer"
        >
          {lang.terms.PRIVACY_POLICY}
        </Link>
      </UI.Text>
    )
  }
}

const verifyCodeSchema = z.object({
  code: z
    .string({ required_error: "Code is required" })
    .min(6, "Code should be 6 characters")
    .max(6, "Code should be 6 characters"),
})

function VerifyCodePage({
  showChooseWalletStep,
  isSigningUp,
  setPage,
  email,
  onLoginComplete,
}: {
  showChooseWalletStep?: boolean
  isSigningUp: boolean
  setPage: React.Dispatch<React.SetStateAction<Page>>
  email: string
  onLoginComplete?: () => void
}) {
  const lang = useLang()
  const formRef = React.useRef<HTMLFormElement | null>(null)
  const { isLoaded: isSignInLoaded, signIn } = useSignIn()
  const { isLoaded: isSignUpLoaded, signUp, setActive } = useSignUp()
  const [lastResult, setLastResult] = React.useState<SubmissionResult>()
  const [isSubmitting, setIsSubmitting] = React.useState(false)
  const [formError, setFormError] = React.useState<string | null>(null)
  const revalidator = useRevalidator()

  const [form, fields] = useForm({
    lastResult,
    constraint: getZodConstraint(verifyCodeSchema),
    shouldValidate: "onSubmit",
    shouldRevalidate: "onSubmit",

    onValidate({ formData }) {
      return parseWithZod(formData, { schema: verifyCodeSchema })
    },

    async onSubmit(e, { formData }) {
      e.preventDefault()
      setIsSubmitting(true)

      const result = parseWithZod(formData, { schema: verifyCodeSchema })

      if (result.status === "success") {
        const { code } = result.value

        if (!isSignInLoaded || !isSignUpLoaded) return

        let createdSessionId: string | null = null
        if (isSigningUp) {
          try {
            const verifyCodeResult = await signUp.attemptEmailAddressVerification({
              code,
            })
            if (verifyCodeResult.status === "complete") {
              createdSessionId = verifyCodeResult.createdSessionId
            }
          } catch (error) {
            setIsSubmitting(false)
            if (isClerkAPIResponseError(error)) {
              setLastResult(
                result.reply({
                  fieldErrors: { code: getClerkErrorMessage(error.errors) },
                }),
              )
              formRef.current?.reset()
            }
            return
          }
        } else {
          try {
            const result = await signIn.attemptFirstFactor({
              strategy: "email_code",
              code,
            })
            if (result.status === "complete") {
              createdSessionId = result.createdSessionId
            }
          } catch (error) {
            setIsSubmitting(false)
            if (isClerkAPIResponseError(error)) {
              setLastResult(
                result.reply({
                  fieldErrors: { code: getClerkErrorMessage(error.errors) },
                }),
              )
              formRef.current?.reset()
            }
            return
          }
        }

        await setActive({ session: createdSessionId })

        if (isSigningUp) {
          try {
            await fetch("/api/create-iyk-user", { method: "POST" })

            if (showChooseWalletStep) {
              revalidator.revalidate()
              setPage(Pages.CHOOSE_WALLET)
              setIsSubmitting(false)
              return
            }
          } catch (e) {
            Sentry.captureException(e)
            setFormError("We could not create your account. Please try again.")
            setIsSubmitting(false)
            return
          }
        }

        revalidator.revalidate()
        onLoginComplete?.()
      }
    },
  })

  return (
    <div className="flex flex-col gap-y-4">
      <div className="flex flex-col gap-3">
        <UI.Title>{toSentenceCase(lang.terms.CHECK_YOUR_EMAIL)}</UI.Title>
        <UI.Text size="sm" className="text-gray-11">
          {toSentenceCase(
            toTemplate<"email">(lang.terms.CHECK_YOUR_EMAIL_DESCRIPTION_1, {
              email,
            }),
          )}
          . {toSentenceCase(lang.terms.CHECK_YOUR_EMAIL_DESCRIPTION_1_CONT)}.
        </UI.Text>
      </div>
      <Form method="POST" ref={formRef} {...getFormProps(form)} className="text-sm">
        <div className="flex flex-col gap-2">
          <InputOTP
            {...getInputProps(fields.code, { type: "text" })}
            onComplete={() => formRef.current?.requestSubmit()}
            data-1p-ignore
            maxLength={6}
            render={({ slots }) => (
              <InputOTPGroup>
                {slots.map((slot, index) => (
                  <InputOTPSlot
                    className={
                      !!fields.code.errors
                        ? slot.isActive
                          ? "ring-offset-1 ring-offset-tomato-11 ring-tomato-5"
                          : "border-tomato-11"
                        : ""
                    }
                    key={index}
                    {...slot}
                  />
                ))}
              </InputOTPGroup>
            )}
          />
          {form.errors && form.errors.length > 0 ? (
            <div id={form.errorId}>
              {form.errors.filter(Boolean).map((error) => (
                <UI.Text key={error} size="sm" className="text-tomato-11">
                  {error}
                </UI.Text>
              ))}
            </div>
          ) : null}
          {fields.code.errors && fields.code.errors.length > 0 ? (
            <div id={fields.code.errorId}>
              {fields.code.errors.filter(Boolean).map((error, idx) => (
                <UI.Text key={idx} size="sm" className="text-tomato-11">
                  {toSentenceCase(
                    error
                      .replace(
                        "You have to try again with the same or another method",
                        lang.terms.CHECK_YOUR_EMAIL_ERROR_1,
                      )
                      .replace("Incorrect code", lang.terms.CHECK_YOUR_EMAIL_ERROR_2),
                  )}
                </UI.Text>
              ))}
            </div>
          ) : null}
        </div>
        <div className="flex flex-col gap-y-2">
          <UI.Button
            type="submit"
            kind="solid"
            size="md"
            disabled={isSubmitting}
            className="w-full mt-3"
          >
            {isSubmitting
              ? toSentenceCase(lang.terms.CHECKING_CODE)
              : toSentenceCase(lang.terms.SUBMIT)}
          </UI.Button>
          {formError && (
            <UI.Text size="xs" className="text-tomato-11">
              {formError}
            </UI.Text>
          )}
        </div>
      </Form>
      <GoBackButton onClick={() => setPage(Pages.EMAIL_INPUT)} />
    </div>
  )
}

export function ChooseWallet({ onLoginComplete }: { onLoginComplete?: () => void }) {
  const lang = useLang()

  return (
    <>
      <div className="flex flex-col gap-3">
        <UI.Title>{toSentenceCase(lang.terms.STORING_YOUR_IYK_ITEMS)}</UI.Title>
        <UI.Text size="sm" className="text-gray-11">
          {toSentenceCase(lang.terms.STORING_YOUR_IYK_ITEMS_DESCRIPTION_1)}
        </UI.Text>
      </div>
      <UI.ButtonGroup className="flex-col gap-2">
        <UI.Button type="submit" kind="solid" size="md" onClick={() => onLoginComplete?.()}>
          {toSentenceCase(lang.terms.COLLECT_WITH_IYK)}
        </UI.Button>
        <AddWalletDialog onWalletCreationComplete={() => onLoginComplete?.()} />
      </UI.ButtonGroup>
    </>
  )
}

function getClerkErrorMessage(errors: ClerkAPIError[]): string[] {
  return errors.map((e) => e.message ?? "")
}

function GoBackButton({ onClick }: { onClick: () => void }) {
  const lang = useLang()

  return (
    <button
      onClick={onClick}
      className="self-start text-gray-11 inline-flex items-center gap-1 text-sm"
    >
      <Icon.ChevronLeft />
      {toSentenceCase(lang.terms.BACK)}
    </button>
  )
}
